import { getProfile } from "@/services/models/userDatas";

export default async function (to, from, next) {
  // Verifica primeiro se a rota exige autenticação
  const requiresAuth = to.matched.some(
    (record) => record.meta.requiresAuth || record.meta.dashboardRequiresAuth
  );

  // Se a rota não exige autenticação, continua normalmente
  if (!requiresAuth) {
    next();
    return;
  }

  // Se a rota exige autenticação, faz a chamada ao backend para verificar a sessão
  let profile = null;

  try {
    const response = await getProfile(); // Faz a requisição para verificar a sessão
    profile = response;
  } catch (error) {
    console.error("Erro ao verificar o perfil de usuario:", error);
  }

  // Se o usuário não está autenticado ou a sessão expirou
  if (!profile) {
    localStorage.clear();
    next({
      path: "/login",
      query: { redirect: to.fullPath },
    });
    return;
  }

  // Verifica o acesso ao dashboard
  if (to.matched.some((record) => record.meta.dashboardRequiresAuth)) {
    if (
      profile &&
      (profile.permissions.is_car_rental_admin ||
        profile.permissions.is_super_admin)
    ) {
      // Se o usuário tem permissões de administrador, permite o acesso
      next();
    } else {
      // Se o usuário não tem permissões, redireciona para login
      next({
        path: "/login",
        query: { redirect: to.fullPath },
      });
    }
    return;
  }

  // Verifica o acesso à página que requer autenticação
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (profile) {
      // Se a sessão é válida, permite o acesso
      next();
    } else {
      // Se não estiver logado, redireciona para a página de login
      next({
        path: "/login",
        query: { redirect: to.fullPath },
      });
    }
    return;
  }

  // Para outras rotas que não exigem autenticação, continua normalmente
  next();
}
